import { useStaticQuery, graphql } from 'gatsby';

export const useBanners = (isMobile) => {
  const {
    allStrapiBanner: { nodes },
  } = useStaticQuery(graphql`
    {
      allStrapiBanner {
        nodes {
          key
          title
          subtitle
          externalUrl
          url
          rank
          actions {
            actionText
            ActionSubtitle
            buttonColor
            buttonTextColor
            actionUrl
            externalUrl
          }
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          textColor
          showOnMobile
          mobileTitle
          googleEvent
          mobileImage {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  if (isMobile) return nodes ? nodes.filter((i) => i.showOnMobile) : [];

  return nodes ? nodes.sort((a, b) => a.rank - b.rank) : [];
};
