import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Slider from 'react-slick';

import { isFieldPresentInStrapi } from '@utils/isFieldPresentInStrapi';
import { event } from '@analytics/gtm';

import type { BannersArgs } from '../types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './MobileBanner.module.scss';

export interface MobileBannerImageArgs {
  title: string;
  mobileImage: {
    localFile: {
      photo?: string;
      childImageSharp?: {
        gatsbyImageData: any;
      };
    };
  };
}

const customPaging = () => <div className={styles.dot} />;

const appendDots = (dots: any[]) => (
  <div className={styles.navigation}>
    <ul className={styles.dots}>
      {dots.map((dot) => (
        <li
          key={dot.key}
          className={cn({
            [styles.dot]: true,
            [styles.dotActive]: dot.props.className === 'slick-active',
          })}
        >
          {dot.props.children}
        </li>
      ))}
    </ul>
  </div>
);

const sliderSettings = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000,
  customPaging,
  appendDots,
  dotsClass: styles.navigation,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  useTransform: false,
  loading: 'eager',
  arrows: false,
};

const renderMobileBannerImage = (banner: MobileBannerImageArgs, isCustomContent?: boolean) => {
  if (isCustomContent) {
    return (
      <img className={styles.image} src={banner.mobileImage?.localFile?.photo} alt={banner.title} />
    );
  }
  return (
    <GatsbyImage
      alt={banner.title}
      className={styles.image}
      imgStyle={{
        objectFit: 'contain',
      }}
      image={banner.mobileImage.localFile.childImageSharp?.gatsbyImageData}
    />
  );
};

export function MobileBanner({ items, customContent }: BannersArgs) {
  const bannerContent = customContent ?? items;

  const handleBannerClick = (bannerUrl: string, googleEvent: string) => {
    if (isFieldPresentInStrapi(googleEvent)) {
      event(`event_site_${googleEvent}`, {
        url: bannerUrl,
      });
    }
  };
  const bannersToRender =
    Array.isArray(bannerContent) &&
    bannerContent.map((banner: any) => (
      <div className={styles.banner} key={`mob-banner-${banner.key}`}>
        {banner.externalUrl ? (
          <a
            className={styles.overlay}
            target="_blank"
            rel="noreferrer"
            href={banner.url !== ' ' ? banner.url : null}
            onClick={() => handleBannerClick(banner.key, banner.googleEvent)}
          >
            {renderMobileBannerImage(banner, !!customContent)}
          </a>
        ) : (
          <Link
            className={styles.overlay}
            to={banner.url !== ' ' ? banner.url : null}
            onClick={() => handleBannerClick(banner.key, banner.googleEvent)}
          >
            {renderMobileBannerImage(banner, !!customContent)}
          </Link>
        )}
      </div>
    ));

  return (
    <div className={styles.banners}>
      <div className={styles.wrapper}>
        <Slider
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sliderSettings}
        >
          {bannersToRender}
        </Slider>
      </div>
    </div>
  );
}
