import React, { useEffect, useState } from 'react';
import { getQueryParams, useQueryParamString } from 'react-use-query-param-string';

import { bannerContent } from '@consts/photobookConsts';
import { useFilter, useProducts } from '@hooks';

import { DesktopBanner, MobileBanner } from '@components/Blocks/Banners';
import { FilterMultiSelect } from '@components/Blocks/FilterMultiSelect';
import { FilteredProductsBlock } from '@components/Blocks/FilteredProductsBlock';
import { MiniServices } from '@components/Blocks/MiniServices';
import { Tags } from '@components/Blocks/Tags';
import ThinPromo from '@components/Blocks/ThinPromo';
import Layout from '@components/Layouts/Layout';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';

import { event } from '@analytics/gtm';

import { allowedCoverTypes, allowedFormatTypes, allowedPageCounts } from '@consts/goodsConsts';

import {
  coverTypeFilterOptions,
  formatTypeFilterOptions,
  pageCountFilterOptions,
} from '../../data/filters';

import styles from './photobooks.module.scss';

export function Head() {
  return (
    <>
      <SEO
        title="Фотокнига на заказ онлайн: фотоальбом из своих фотографий, печать"
        description="Создайте фотокнигу из собственных фото онлайн в удобном конструкторе Периодики! Закажите изготовление и печать индивидуального фотоальбома из множества дизайнов!"
      />
      <YandexTableau />
    </>
  );
}

function PhotobooksPage() {
  const params = getQueryParams();
  const { photobooks } = useProducts();
  const { getItemsByProperties } = useFilter();

  // TODO: посмотреть зачем мы устанавливаем значения, но никогда не используем. Только для rerender?
  const [, setPageCount] = useQueryParamString('pageCount', '');
  const [, setCoverType] = useQueryParamString('coverType', '');
  const [, setProductFormat] = useQueryParamString('productFormat', '');

  const products = photobooks.filter((p) => p.productType !== 'wedding');

  const [selectedPageCount, setSelectedPageCount] = useState([]);
  const [selectedCoverType, setSelectedCoverType] = useState([]);
  const [selectedProductFormat, setSelectedProductFormat] = useState([]);

  const [selectedPageTags, setSelectedPageTags] = useState([]);
  const [selectedCoverTags, setSelectedCoverTags] = useState([]);
  const [selectedFormatTags, setSelectedFormatTags] = useState([]);

  const [items, setItems] = useState([]);

  const handleSelectFormatType = (value, checked, isFromUrl = false) => {
    const selectedItem = formatTypeFilterOptions.find((item) => item.value === value);
    if (checked) {
      setSelectedProductFormat((prev) => [...prev, value]);
      if (selectedItem) {
        setSelectedFormatTags((prev) => [...prev, selectedItem]);
      }
    } else {
      setSelectedProductFormat((prev) => prev.filter((prevFormatType) => prevFormatType !== value));
      if (selectedItem) {
        setSelectedFormatTags((prev) =>
          prev.filter((prevFormatTag) => prevFormatTag !== selectedItem)
        );
      }
    }
    if (!isFromUrl) {
      event(`event_site_filter_formatType`);
    }
  };

  const handleSelectPageCount = (value, checked, isFromUrl = false) => {
    const selectedItem = pageCountFilterOptions.photobooks.find((item) => item.value === value);
    if (checked) {
      setSelectedPageCount((prev) => [...prev, value]);
      if (selectedItem) {
        setSelectedPageTags((prev) => [...prev, selectedItem]);
      }
    } else {
      setSelectedPageCount((prev) => prev.filter((prevPageCount) => prevPageCount !== value));
      if (selectedItem) {
        setSelectedPageTags((prev) => prev.filter((prevPageTag) => prevPageTag !== selectedItem));
      }
    }
    if (!isFromUrl) {
      event(`event_site_filter_pageCount`);
    }
  };

  const handleSelectCoverType = (value, checked, isFromUrl) => {
    const selectedItem = coverTypeFilterOptions.find((item) => item.value === value);
    if (checked) {
      setSelectedCoverType((prev) => [...prev, value]);
      if (selectedItem) {
        setSelectedCoverTags((prev) => [...prev, selectedItem]);
      }
    } else {
      setSelectedCoverType((prev) => prev.filter((prevCoverType) => prevCoverType !== value));
      if (selectedItem) {
        setSelectedCoverTags((prev) =>
          prev.filter((prevCoverTag) => prevCoverTag !== selectedItem)
        );
      }
    }
    if (!isFromUrl) {
      event(`event_site_filter_coverType`);
    }
  };

  const handleRemoveFormatTag = (tag) => {
    setSelectedProductFormat((prev) =>
      prev.filter((prevSelectedFormatType) => prevSelectedFormatType !== tag.value)
    );
    setSelectedFormatTags((prev) =>
      prev.filter((prevSelectedFormatTag) => prevSelectedFormatTag.value !== tag.value)
    );
    event(`event_site_filter_formatType-remove`);
  };

  const handleRemovePageTag = (tag) => {
    setSelectedPageCount((prev) => prev.filter((prevPageCount) => prevPageCount !== tag.value));
    setSelectedPageTags((prev) =>
      prev.filter((prevSelectedPageTag) => prevSelectedPageTag.value !== tag.value)
    );
    event(`event_site_filter_pageCount-remove`);
  };

  const handleRemoveCoverTag = (tag) => {
    setSelectedCoverType((prev) =>
      prev.filter((prevSelectedCoverType) => prevSelectedCoverType !== tag.value)
    );
    setSelectedCoverTags((prev) =>
      prev.filter((prevSelectedCoverTag) => prevSelectedCoverTag.value !== tag.value)
    );
    event(`event_site_filter_coverType-remove`);
  };

  useEffect(() => {
    const itemsToSelect = getItemsByProperties({
      pageCounts: selectedPageCount,
      coverTypes: selectedCoverType,
      productFormats: selectedProductFormat,
    });

    const normalizedPageCount = selectedPageCount.join(',');
    const normalizedCoverType = selectedCoverType.join(',');
    const normalizedProductFormat = selectedProductFormat.join(',');

    setPageCount(normalizedPageCount);
    setCoverType(normalizedCoverType);
    setProductFormat(normalizedProductFormat);

    setItems(itemsToSelect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPageCount, selectedCoverType, selectedProductFormat]);

  useEffect(() => {
    let isMounted = true;
    if (params && isMounted) {
      const {
        pageCount: pageCountFromUrl,
        coverType: coverTypeFromUrl,
        productFormat: productFormatFromUrl,
      } = params;

      if (pageCountFromUrl) {
        const parsedPageCount = pageCountFromUrl.split(',');

        parsedPageCount.forEach((item) => {
          if (allowedPageCounts.indexOf(item) !== -1) {
            handleSelectPageCount(item, true, true);
          }
        });
      }

      if (coverTypeFromUrl) {
        const parsedCoverType = coverTypeFromUrl.split(',');

        parsedCoverType.forEach((item) => {
          if (allowedCoverTypes.indexOf(item) !== -1) {
            handleSelectCoverType(item, true, true);
          }
        });
      }

      if (productFormatFromUrl) {
        const parsedFormatType = productFormatFromUrl.split(',');

        parsedFormatType.forEach((item) => {
          if (allowedFormatTypes.indexOf(item) !== -1) {
            handleSelectFormatType(item, true, true);
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className={styles.bannerBlock}>
        <DesktopBanner customContent={bannerContent} />
        <MobileBanner customContent={bannerContent} />
      </div>
      <div className={styles.filters}>
        <FilterMultiSelect
          title="Обложка"
          onChange={handleSelectCoverType}
          options={coverTypeFilterOptions}
          customClassName={styles.filter}
          selectedOptions={selectedCoverType}
        />
        <FilterMultiSelect
          title="Кол-во страниц"
          onChange={handleSelectPageCount}
          options={pageCountFilterOptions.photobooks}
          customClassName={styles.filter}
          selectedOptions={selectedPageCount}
        />
        <FilterMultiSelect
          title="Формат"
          onChange={handleSelectFormatType}
          options={formatTypeFilterOptions}
          customClassName={styles.filterFormat}
          selectedOptions={selectedProductFormat}
        />
      </div>

      {(!!selectedCoverTags.length || !!selectedPageTags.length || !!selectedFormatTags.length) && (
        <div className={styles.selectedFilters}>
          {selectedCoverTags && (
            <Tags selectedTags={selectedCoverTags} onTagRemove={handleRemoveCoverTag} />
          )}
          {selectedPageTags && (
            <Tags selectedTags={selectedPageTags} onTagRemove={handleRemovePageTag} />
          )}
          {selectedFormatTags && (
            <Tags selectedTags={selectedFormatTags} onTagRemove={handleRemoveFormatTag} />
          )}
        </div>
      )}
      <FilteredProductsBlock
        products={
          !!selectedCoverTags.length || !!selectedPageTags.length || !!selectedFormatTags.length
            ? items
            : products
        }
      />
      <ThinPromo
        title="Скидка 7% при покупке от 3-х фотокниг"
        text="Используйте промокод <b>ФОТОКНИГИ7</b> при оплате заказа."
      />
      <MiniServices header="Услуги" />
    </Layout>
  );
}
export default PhotobooksPage;
