import React from 'react';
import cn from 'classnames';
import { CaretLeft } from '@periodica/ui-kit/icons';

import styles from './Arrow.module.scss';

export type ArrowType = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  externalClassName?: string;
};

export function PrevArrow({ className, style, onClick, externalClassName }: ArrowType) {
  return (
    <div
      className={cn({
        [styles.arrow]: true,
        ...(className && { [className]: true }),
        ...(externalClassName && { [externalClassName]: true }),
      })}
      style={{ ...style }}
      onClick={onClick}
    >
      <div className={styles.iconBlock}>
        <CaretLeft size={16} />
      </div>
    </div>
  );
}
