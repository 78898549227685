import photobookBanner from '@images/photobooks/photobookBanner.jpg';
import mobilePhotobookBanner from '@images/photobooks/mobilePhotobookBanner.jpg';

export const bannerContent = [
  {
    key: '1',
    title: 'Фотокниги с вашей историей',
    rank: 1,
    showOnMobile: false,
    image: {
      localFile: {
        photo: photobookBanner,
      },
    },
    mobileImage: {
      localFile: {
        photo: mobilePhotobookBanner,
      },
    },
  },
];
