import React from 'react';
import cn from 'classnames';
import { CaretRight } from '@periodica/ui-kit/icons';

import styles from './Arrow.module.scss';

import type { ArrowType } from './PrevArrow';

export function NextArrow({ className, style, onClick, externalClassName }: ArrowType) {
  return (
    <div
      className={cn({
        [styles.arrow]: true,
        ...(className && { [className]: true }),
        ...(externalClassName && { [externalClassName]: true }),
      })}
      style={{ ...style }}
      onClick={onClick}
    >
      <div className={styles.iconBlock} onClick={onClick}>
        <CaretRight size={16} />
      </div>
    </div>
  );
}
