import { useStaticQuery, graphql } from 'gatsby';

export const useTopProducts = () => {
  const {
    allStrapiTop: { nodes },
  } = useStaticQuery(graphql`
    {
      allStrapiTop {
        nodes {
          product {
            id
            title
            subtitle
            labels {
              id
              name
            }
            url
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    width: 320
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            options {
              ... on STRAPI__COMPONENT_PRODUCT_COMPONENTS_PAGE_OPTION {
                id
                price
                optionSalePrice
              }
            }
          }
        }
      }
    }
  `);
  return nodes || [];
};
