import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { isFieldPresentInStrapi } from '@utils/isFieldPresentInStrapi';

import Slider from 'react-slick';

import { event } from '@analytics/gtm';
import type { BannersArgs } from '../types';

import { NextArrow } from '../components/arrows/NextArrow';
import { PrevArrow } from '../components/arrows/PrevArrow';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './DesktopBanner.module.scss';

export interface DesktopBannerImageArgs {
  title: string;
  image: {
    localFile?: {
      photo?: string;
      childImageSharp?: {
        gatsbyImageData: any;
      };
    };
  };
}

const customPaging = () => <div className={styles.dot} />;

const appendDots = (dots: any[]) => (
  <div className={styles.navigation}>
    <ul className={styles.dots}>
      {dots.map((dot) => (
        <li
          key={dot.key}
          className={cn({
            [styles.dot]: true,
            [styles.dotActive]: dot.props.className === 'slick-active',
          })}
        >
          {dot.props.children}
        </li>
      ))}
    </ul>
  </div>
);

const sliderSettings = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000,
  customPaging,
  appendDots,
  dotsClass: styles.navigation,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow externalClassName={styles.arrowRight} />,
  prevArrow: <PrevArrow externalClassName={styles.arrowLeft} />,
  useTransform: false,
};

const renderBannerImage = (banner: DesktopBannerImageArgs, isCustomContent?: boolean) => {
  if (isCustomContent) {
    return (
      <img
        className={styles.image}
        src={banner.image?.localFile?.photo}
        alt={banner.title}
        style={{
          objectPosition: '15% center',
          objectFit: 'cover',
        }}
      />
    );
  }
  return (
    <GatsbyImage
      alt={banner.title}
      className={styles.image}
      imgStyle={{
        objectPosition: '10% center',
      }}
      image={banner.image.localFile?.childImageSharp?.gatsbyImageData}
    />
  );
};

export function DesktopBanner({ items, customContent, customClassName }: BannersArgs) {
  const bannerContent = customContent ?? items;

  const handleBannerClick = (bannerUrl: string, googleEvent: string) => {
    if (isFieldPresentInStrapi(googleEvent)) {
      event(`event_site_${googleEvent}`, {
        url: bannerUrl,
      });
    }
  };

  const bannersToRender =
    Array.isArray(bannerContent) &&
    bannerContent.map((banner: any) => (
      <div
        className={cn({
          [styles.banner]: true,
          ...(customClassName ? { [customClassName]: true } : {}),
        })}
        key={`${banner.key}`}
      >
        {banner?.externalUrl ? (
          <a
            className={styles.overlay}
            target="_blank"
            rel="noreferrer"
            href={banner.url !== ' ' ? banner.url : null}
            onClick={() => handleBannerClick(banner.key, banner.googleEvent)}
          >
            {renderBannerImage(banner, !!customContent)}
          </a>
        ) : (
          <Link
            className={styles.overlay}
            to={banner.url !== ' ' ? banner.url : null}
            onClick={() => handleBannerClick(banner.key, banner.googleEvent)}
          >
            {renderBannerImage(banner, !!customContent)}
          </Link>
        )}
      </div>
    ));

  return (
    <div className={styles.banners}>
      <div className={styles.wrapper}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...sliderSettings}>{bannersToRender}</Slider>
      </div>
    </div>
  );
}
